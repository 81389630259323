<script>
export default {
};
</script>

<!-- <template>
  <b-alert show dismissible variant="warning">
    <h5 class="font-size-14 text-danger">Risk Warning</h5>
    <p class="mb-0">
      Cryptocurrencies are a high-risk investment that is not 
      suitable for all investors. Cryptocurrencies are subject to 
      extreme price volatility. Investors should be prepared to lose 
      their entire investment.
    </p>
  </b-alert>
</template> -->

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="flex-grow-1 align-self-center">
            <h5 class="font-size-14 text-danger">Risk Warning</h5>
            <p class="mb-0 text-muted">
              Cryptocurrencies are a high-risk investment that is not 
              suitable for all investors. Cryptocurrencies are subject to 
              extreme price volatility. Investors should be prepared to lose 
              their entire investment.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>